import {Avatar} from './enums/avatar.enum';
import {Theme} from './enums/theme.enum';
import {Forecast} from './enums/forecast.enum';

export const GEO_LOCATION_URL = 'https://api.sypexgeo.net/';
// export const BASE_URL =  'https://www.meteoprog.ua/';
// export const BASE_URL_PL =  'https://www.meteoprog.pl/';
export const BASE_URL =  'https://www.meteoprog.fedorov.net.ua/';
export const BASE_URL_PL =  'https://www.meteoprog.fedorov.net.ua/';
export const FIND_CITY_URL = `search/json/`;
export const GET_CITIES_URL = `search/cities/json/`;
export const GET_WEATHER_URL = `search/cities/weather/json/`;
export const GET_CODE_URL = `weatherwidget/`;
export const DEFAULT_CITY_ID = '349';

export const DEFAULT_WIDTH = 260;
export const DEFAULT_WIDTH_MINIMAL = 4;

export const DEFAULT_SETTINGS = {
  width: DEFAULT_WIDTH,
  theme: Theme.Standart,
  opacity: 0.2,
  HPW: true,
  description: false,
  avatar: Avatar.None,
  extendedForecast: Forecast.ByDays,
  border: true
};

export const THEMES = [
  {
    data: {
      title: 'standart',
      value: Theme.Standart,
      preview: '/assets/images/theme_std-preview.jpg',
      checked: true
    },
    settings: {
      headerColor: {
        r: 237,
        g: 237,
        b: 237,
        a: DEFAULT_SETTINGS.opacity
      },
      bodyColor: {
        r: 248,
        g: 248,
        b: 248,
        a: DEFAULT_SETTINGS.opacity
      },
      fontColor: {
        r: 48,
        g: 48,
        b: 48,
        a: 1
      }
    }
  },
  {
    data: {
      title: 'light',
      value: Theme.Light,
      preview: '/assets/images/theme_light-preview.jpg',
      checked: false
    },
    settings: {
      headerColor: {
        r: 245,
        g: 245,
        b: 245,
        a: DEFAULT_SETTINGS.opacity
      },
      bodyColor: {
        r: 255,
        g: 255,
        b: 255,
        a: DEFAULT_SETTINGS.opacity
      },
      fontColor: {
        r: 82,
        g: 82,
        b: 82,
        a: 1
      }
    }
  },
  {
    data: {
      title: 'dark',
      value: Theme.Dark,
      preview: '/assets/images/theme_dark-preview.jpg',
      checked: false
    },
    settings: {
      headerColor: {
        r: 0,
        g: 0,
        b: 0,
        a: DEFAULT_SETTINGS.opacity
      },
      bodyColor: {
        r: 26,
        g: 26,
        b: 26,
        a: DEFAULT_SETTINGS.opacity
      },
      fontColor: {
        r: 255,
        g: 255,
        b: 255,
        a: 1
      }
    }
  },
  {
    data: {
      title: 'minimal',
      value: Theme.Minimal,
      preview: '/assets/images/theme_min-preview.jpg',
      checked: false
    },
    settings: {
      headerColor: {
        r: 255,
        g: 255,
        b: 255,
        a: DEFAULT_SETTINGS.opacity
      },
      bodyColor: {
        r: 248,
        g: 248,
        b: 248,
        a: DEFAULT_SETTINGS.opacity
      },
      fontColor: {
        r: 48,
        g: 48,
        b: 48,
        a: 1
      }
    }
  },
  {
    data: {
      title: 'theme_5',
      value: Theme.Theme_5,
      preview: '',
      checked: false,
      disabled: true
    },
    settings: {
      headerColor: {
        r: 233,
        g: 242,
        b: 247,
        a: DEFAULT_SETTINGS.opacity
      },
      bodyColor: {
        r: 242,
        g: 246,
        b: 250,
        a: DEFAULT_SETTINGS.opacity
      },
      fontColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1
      }
    }
  },
  {
    data: {
      title: 'theme_6',
      value: Theme.Theme_6,
      preview: '',
      checked: false,
      disabled: true
    },
    settings: {
      headerColor: {
        r: 64,
        g: 179,
        b: 249,
        a: DEFAULT_SETTINGS.opacity
      },
      bodyColor: {
        r: 255,
        g: 255,
        b: 255,
        a: DEFAULT_SETTINGS.opacity
      },
      fontColor: {
        r: 48,
        g: 48,
        b: 48,
        a: 1
      }
    }
  },
  {
    data: {
      title: 'theme_7',
      value: Theme.Theme_7,
      preview: '',
      checked: false,
      disabled: true
    },
    settings: {
      headerColor: {
        r: 182,
        g: 222,
        b: 255,
        a: DEFAULT_SETTINGS.opacity
      },
      bodyColor: {
        r: 248,
        g: 248,
        b: 248,
        a: DEFAULT_SETTINGS.opacity
      },
      fontColor: {
        r: 48,
        g: 48,
        b: 48,
        a: 1
      }
    }
  }
];

export const EXT_FORECAST = [
  {
    title: 'hide',
    value: Forecast.None,
    checked: false
  },
  {
    title: 'hourly',
    value: Forecast.ByHours,
    checked: false
  },
  {
    title: 'daily',
    value: Forecast.ByDays,
    checked: true
  }
];

export const AVATARS = [
  {
    title: 'none',
    value: Avatar.None,
    checked: true,
    image: '/assets/images/new_design/mini-ava-no.png'
  },
  {
    title: 'man',
    value: Avatar.Man,
    checked: false,
    image: '/assets/images/new_design/mini-ava-boy.png'
  },
  {
    title: 'woman',
    value: Avatar.Woman,
    checked: false,
    image: '/assets/images/new_design/mini-ava-girl.png'
  }
];

export const SITE_KEY = '6LctXz0UAAAAANcdbj0vpgJqHtBga4Nu7VgA6Wb9';
export const MAX_RANDOM_ID = 65535;
