import {Component, OnInit} from '@angular/core';
import {CitiesService} from './services/cities.service';
import {CodeGenService} from './services/codegen.service';
import {TranslateService} from '@ngx-translate/core';
import {BASE_URL, DEFAULT_WIDTH, DEFAULT_WIDTH_MINIMAL, SITE_KEY, THEMES} from './configuration';
import {CitiesWeatherInfo} from './interfaces/cities-weather-info';
import {Informer} from './interfaces/informer';
import {CityData} from './interfaces/city-data';
import {ConfigurationService} from './services/configuration.service';
import {ThemeInfo} from './interfaces/theme-info';
import {Theme} from './enums/theme.enum';
import {RGBA} from 'ngx-color';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CodeRequest} from './interfaces/code-request';
import {CodeResponse} from './interfaces/code-response';
import {RgbaPipe} from './pipes/rgba.pipe';
import {Avatar} from './enums/avatar.enum';
import {Forecast} from './enums/forecast.enum';
import {Language} from './enums/language.enum';
import {finalize} from 'rxjs/operators';
import {City} from './interfaces/city';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
  ]
})

export class AppComponent implements OnInit {

  /**
   * Энумератор всевозможных тем
   */
  readonly Theme = Theme;

  /**
   * Captcha-ключ для генерации информеров
   */
  readonly SITE_KEY = SITE_KEY;

  /**
   * Перечисление языков
   */
  readonly Language = Language;

  /**
   * Массив информеров для передачи в контейнер вывода информеров
   */
  informers: Array<Informer> = [];

  /**
   * Цвет фона сайта
   */
  siteColor: RGBA;

  /**
   * Форма с параметрами информера
   */
  form: FormGroup;

  /**
   * Отправляется ли форма
   */
  submitting = false;

  /**
   * Сгенерированный код для вставки на сайт
   */
  generatedCode: string;

  /**
   * Домены для разных языков информера (требуются на бекенде)
   */
  domains: Map<string, string> = new Map([
    [Language.Rus, 'https://www.meteoprog.ua/ru/'],
    [Language.Ukr, 'https://www.meteoprog.ua/ua/'],
    [Language.Eng, 'https://www.meteoprog.com/'],
    [Language.Pln, 'https://www.meteoprog.pl/']
  ]);

  /**
   * Конструктор компонента.
   *
   * @param {CitiesService} citiesService
   * @param {CodeGenService} codeGenService
   * @param {TranslateService} translateService
   * @param {ConfigurationService} configurationService
   * @param {FormBuilder} formBuilder
   * @param {RgbaPipe} rgbaPipe
   */
  constructor(private citiesService: CitiesService,
              private codeGenService: CodeGenService,
              private translateService: TranslateService,
              private configurationService: ConfigurationService,
              private formBuilder: FormBuilder,
              private rgbaPipe: RgbaPipe) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use('ru');
  }

  /**
   * Слушатель события добавления города
   *
   * @param {CityData} city
   */
  onCityAddedHandler(city: CityData): void {
    this.citiesService.getWeather([city[5]])
      .subscribe((citiesWeatherInfo: CitiesWeatherInfo) => {
        this.informers.push({
          cityID: city[5],
          city: [city[0], city[4], city[6]],
          weather: citiesWeatherInfo[city[5]]
        });
        this.generatedCode = null;
    });
  }

  /**
   * Слушатель события удаления города
   *
   * @param {CityData} city
   */
  onCityDeletedHandler(city: CityData): void {
    const deleteIndex = this.informers.findIndex(elem => elem.cityID === city[5]);
    this.informers.splice(deleteIndex, 1);
    this.generatedCode = null;
  }

  /**
   * Слушатель события обновления городов
   *
   * @param {Array<City>} cities
   */
  onCityUpdateHandler(cities: Array<City>): void {
    this.informers = this.informers.map((informer: Informer, i: number) => {
      informer.city = {...cities[i]};
      return informer;
    });

    this.citiesService.getWeather(this.informers.map(elem => elem.cityID))
      .subscribe((citiesWeatherInfo: CitiesWeatherInfo) => {
        this.informers = this.informers.map((informer: Informer) => {
          informer.weather.cloudDescription = citiesWeatherInfo[informer.cityID].cloudDescription;
          informer.weather.precipitation = citiesWeatherInfo[informer.cityID].precipitation;
          return informer;
        });
      });
    this.generatedCode = null;
  }

  onBgImageClick(): void {
    this.configurationService.bgImage = window.prompt(this.translateService.instant('input_link'), `${BASE_URL}images/inf-bg.jpg`)
      || this.configurationService.bgImage;
    this.generatedCode = null;
  }

  widthChanged(): void {
    this.generatedCode = null;
  }

  onOpacityChange(): void {
    this.generatedCode = null;
  }

  onThemeSettingsChange(event: ThemeInfo): void {
    // если мы перешли на минимальную тему
    if (event.theme === Theme.Minimal) {
      this.configurationService.informerSettings.width = DEFAULT_WIDTH_MINIMAL;
    } else {
      // если на какую-то другую с минимальной
      if (this.configurationService.informerSettings.width <= DEFAULT_WIDTH_MINIMAL) {
        // то восстанавливаем дефолтную ширину
        this.configurationService.informerSettings.width = DEFAULT_WIDTH;
      }
    }
    this.generatedCode = null;
  }

  onResetSettings(): void {
    this.configurationService.resetSettings();
    this.siteColor = null;
    this.generatedCode = null;
  }

  onFormSubmit(): void {
    if (this.form.valid) {
      this.submitting = true;
      const cities = this.informers.map(elem => elem.city);
      const params: CodeRequest = {
        domain: this.domains.get(this.translateService.currentLang),
        constructor__lng: this.translateService.currentLang,
        width: document.getElementsByClassName('informers-border')[0].clientWidth,
        inf_width: this.configurationService.informerSettings.width,
        'tema-oform': THEMES[this.configurationService.themeInfo.theme].data.title,
        'cvet-zag': this.rgbaPipe.transform(this.configurationService.themeInfo.settings.headerColor),
        'cvet-inf': this.rgbaPipe.transform(this.configurationService.themeInfo.settings.bodyColor),
        'cvet-shr': this.rgbaPipe.transform(this.configurationService.themeInfo.settings.fontColor),
        prozrachnost_fona: (1 - this.configurationService.informerSettings.opacity) * 100,
        vldv: this.configurationService.informerSettings.HPW ? 'on' : 'off',
        ava: this.configurationService.informerSettings.avatar === Avatar.Man ? 'man' :
             this.configurationService.informerSettings.avatar === Avatar.Woman ? 'woman' : 'noava',
        desc: this.configurationService.informerSettings.description ? 'on' : 'off',
        'r-prog': this.configurationService.informerSettings.extendedForecast === Forecast.ByDays ? 'po-dnyam' :
                  this.configurationService.informerSettings.extendedForecast === Forecast.ByHours ? 'po-chasam' : 'ne-pokazyvat',
        border: this.configurationService.informerSettings.border ? 'on' : 'off',
        www_url: this.form.controls['www_url'].value,
        mylo: this.form.controls['mylo'].value,
        bg_image: this.configurationService.bgImage,
        city_ids: this.informers.map(elem => elem.cityID).join(','),
        informer_height: document.getElementsByClassName('informers-border')[0].clientHeight
      };
      this.codeGenService.getCodeParams(params, cities)
        .pipe(finalize(() => this.submitting = false))
        .subscribe(
          (codeResponse: CodeResponse) => this.generatedCode = this.codeGenService.getCode(codeResponse, cities, params.width),
          (error) => this.generatedCode = JSON.stringify(error)
        );
    } else {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsDirty({ onlySelf: true });
      });
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      iaccept: [false, Validators.requiredTrue],
      www_url: ['', [
                      Validators.required,
                      Validators.pattern('^(https?:\\/\\/)?([\\da-zа-яё\\.-]+)\\.([a-zа-яё\\.]{2,6})([\\/\\w \\.-]*)*\\/?')]
               ],
      mylo: ['', [Validators.required, Validators.email]],
      captcha: ['', Validators.required]
    });
  }

}
