import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {MAX_RANDOM_ID} from '../../configuration';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RangeComponent),
  multi: true
};

/**
 * Компонент выбора значения из диапазона (ползунок)
 */
@Component({
  selector: 'app-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class RangeComponent implements OnInit {

  // -----------------------------
  //  Input properties
  // -----------------------------

  /**
   * Серая надпись над компонентом
   */
  @Input() label = '';

  /**
   * Подпись к текстовому полю (текущему значению ползунка)
   */
  @Input() rangeLabel = '';

  /**
   * Наименьшее значение ползунка
   */
  @Input() rangeMin = 0;

  /**
   * Наибольшее значение ползунка
   */
  @Input() rangeMax = 100;

  /**
   * Показывать ли прогресс в процентах под ползунком
   */
  @Input() showPercentProgress = false;

  /**
   * Показывать ли текстовое поле со значением ползунка
   */
  @Input() showRangeValue = true;

  /**
   * Елиницы измерения для значения ползунка
   */
  @Input() units = 'px';

  // -----------------------------
  //  Public properties
  // -----------------------------

  /**
   * Случайный ID для связи ползунка и подписи к нему
   */
  randomID = Math.floor((Math.random() * MAX_RANDOM_ID) + 1);

  /**
   * Текущее значение прогресса в процентах
   */
  percentProgress: number;

  /**
   * Текущее значение прогресса, округленное до целого
   */
  roundedPercentProgress: number;

  /**
   * Горизонтальное положение надписи с процентами (под ползунком)
   */
  trackValuePosition = 0;

  /**
   * Текущее значение прогресса без учета границ
   */
  rangeValue: number;

  // -----------------------------
  //  Private properties
  // -----------------------------

  /**
   * Заглушки для Функций обратного вызова
   */
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // -----------------------------
  //  Public functions
  // -----------------------------

  /**
   * Геттер
   */
  get value(): number {
    return this.rangeValue;
  };

  /**
   * Сеттер
   */
  set value(v: number) {
    if (v !== this.rangeValue) {
      this.rangeValue = v;
      this.updatePercentProgress();
      this.onChangeCallback(v);
    }
  }

  /**
   * Обработчик потери фокуса
   */
  onBlur(): void {
    this.onTouchedCallback();
  }

  /**
   * Обработчик записи значения
   */
  writeValue(value: number): void {
    if (this.rangeValue !== value) {
      this.rangeValue = value;
      this.updatePercentProgress();
    }
  }

  /**
   * Обработчик изменения значения
   */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /**
   * Обработчик начала изменений
   */
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  /**
   * Обработчик изменения модели
   * Вызывается при изменении значения в текстовом поле
   * @param {Event} event Объект события ввода
   */
  onChangeInputModel(event: Event): void {
    const tmp = parseInt((event.target as HTMLInputElement).value, 10);
    if (this.rangeMin <= tmp && tmp <= this.rangeMax) {
      this.rangeValue = tmp;
      this.updatePercentProgress();
      this.onChangeCallback(this.rangeValue);
    }
  }

  /**
   * Обработчик изменения модели
   * Вызывается при изменении значения ползунка
   * @param {Event} event Объект события ввода
   */
  onChangeRangeModel(event: Event): void {
    this.rangeValue = parseInt((event.target as HTMLInputElement).value, 10);
    this.updatePercentProgress();
    this.onChangeCallback(this.rangeValue);
  }

  // -----------------------------
  //  Private functions
  // -----------------------------

  /**
   * Обновить значение прогресса в процентах
   */
  private updatePercentProgress(): void {
    this.percentProgress = (this.rangeValue - this.rangeMin) / (this.rangeMax - this.rangeMin) * 100;
    this.roundedPercentProgress = Math.round(this.percentProgress);
    this.trackValuePosition = this.percentProgress * (this.rangeMax - this.rangeMin) / (this.rangeMax - this.rangeMin + 18);
  }

  // -----------------------------
  //  Lifecycle functions
  // -----------------------------

  /**
   * Инициализация компонента
   */
  ngOnInit(): void {
    this.updatePercentProgress();
  }
}
