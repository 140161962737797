import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemeSettings } from '../../interfaces/theme-settings';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ThemeSettingsComponent),
  multi: true
};

export const DEFAULT_THEME_SETTINGS = {
  headerColor: {
    r: 255,
    g: 255,
    b: 255,
    a: 0.5
  },
  bodyColor: {
    r: 255,
    g: 255,
    b: 255,
    a: 0.5
  },
  fontColor: {
    r: 0,
    g: 0,
    b: 0,
    a: 1
  }
};

@Component({
  selector: 'app-theme-settings',
  templateUrl: './theme-settings.component.html',
  styleUrls: ['./theme-settings.component.scss'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class ThemeSettingsComponent implements  ControlValueAccessor, OnInit {

  themeSettings: ThemeSettings;
  percentOpacity: number;

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  private compareSettings(newSettings: ThemeSettings) {
    return (newSettings.headerColor === this.themeSettings.headerColor) &&
      (newSettings.bodyColor === this.themeSettings.bodyColor) &&
      (newSettings.fontColor === this.themeSettings.fontColor);
  }

  // get accessor
  get value(): ThemeSettings {
    return this.themeSettings;
  };

  // set accessor including call the onchange callback
  set value(v: ThemeSettings) {
    if (!this.compareSettings(v)) {
      this.themeSettings = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: ThemeSettings) {
    if (value && !this.compareSettings(value)) {
      this.themeSettings = value;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onOnColorChange(): void {
    this.onChangeCallback(this.themeSettings);
  }

  ngOnInit() {
    if (!this.themeSettings) {
      this.themeSettings = {...DEFAULT_THEME_SETTINGS};
    }
  }

}
