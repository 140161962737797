import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ThemeInfo} from '../../interfaces/theme-info';
import {ThemeItem} from '../../interfaces/theme-item';
import {OptionItem} from '../../interfaces/option-item';
import {Theme} from '../../enums/theme.enum';
import {ThemeSettings} from '../../interfaces/theme-settings';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ThemeSwitcherComponent),
  multi: true
};

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class ThemeSwitcherComponent implements ControlValueAccessor, OnInit {

  @Input() themeItems: Array<ThemeItem>;
  themeOptions: Array<OptionItem>;
  currentTheme: Theme = Theme.Standart;

  private _themeInfo: ThemeInfo;
  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor() { }

  private compareValues(v: ThemeInfo) {
    return this._themeInfo && (this._themeInfo.theme === v.theme) && (this._themeInfo.settings.headerColor === v.settings.headerColor)
    && (this._themeInfo.settings.fontColor === v.settings.fontColor) && (this._themeInfo.settings.bodyColor === v.settings.bodyColor);
  }

  private updateValues(v: ThemeInfo): void {
    this._themeInfo = v;
    this.currentTheme = v.theme;
    this.themeItems[this.currentTheme].settings = v.settings;
  }

  // get accessor
  get value(): ThemeInfo {
    this._themeInfo.theme = this.currentTheme;
    this._themeInfo.settings = this.themeItems[this.currentTheme].settings;
    return this._themeInfo;
  };

  // set accessor including call the onchange callback
  set value(v: ThemeInfo) {
    if (!this.compareValues(v)) {
      this.updateValues(v);
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  writeValue(v: ThemeInfo) {
    if (v && !this.compareValues(v)) {
      this.updateValues(v);
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  currentThemeChange(v: Theme): void {
    const newValue: ThemeInfo = {
      theme: v,
      settings: this.themeItems[v].settings
    };

    this.updateValues(newValue);
    this.onChangeCallback(newValue);
  }

  onThemeSettingsChange(settings: ThemeSettings): void {
    const newValue: ThemeInfo = {theme: this.currentTheme, settings};
    this.updateValues(newValue);
    this.onChangeCallback(newValue);
  }

  ngOnInit() {
    this.themeOptions = this.themeItems.map(elem => elem.data);
  }

}
