import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { GeoLocationResponse } from '../../interfaces/geo-location-response';
import { SearchResponse } from '../../interfaces/search-response';
import { DEFAULT_CITY_ID } from '../../configuration';
import { CitiesResponse } from '../../interfaces/cities-response';
import { CityData } from '../../interfaces/city-data';
import { CitiesService } from '../../services/cities.service';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

/**
 * Компонент выбора городов из выпадающего списка по подстроке
 */
@Component({
  selector: 'app-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.scss']
})
export class CitySelectComponent implements OnInit {

  // -----------------------------
  //  Public properties
  // -----------------------------

  /**
   * Массив городов, загруженных в компонент
   */
  cities: Array<CityData> = [];

  /**
   * Input-контрол для отслеживания изменений в нем при вводе первых букв города
   */
  cityCtrl = new FormControl();

  /**
   * Отфильтрованные города по первым буквам
   */
  filteredCities: Observable<Array<CityData>>;

  /**
   * Признак того, что ни один город не выбран
   */
  noCities = false;

  /**
   * Признак первого события смены языка
   */
  firstChange = true;

  /**
   * Ссылка на DOM-элемент для очистки значения в нем
   */
  @ViewChild('cityInput', {static: false}) cityInput: ElementRef<HTMLInputElement>;

  /**
   * Ссылка на выпадающий список
   */
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  // -----------------------------
  //  Output properties
  // -----------------------------

  /**
   * Событие при добавлении города
   */
  @Output() cityAdded: EventEmitter<CityData> = new EventEmitter();

  /**
   * Событие обновления городов
   */
  @Output() citiesUpdated: EventEmitter<Array<CityData>> = new EventEmitter();

  /**
   * Событие при удалении города
   */
  @Output() cityDeleted: EventEmitter<CityData> = new EventEmitter();

  /**
   * Конструктор компонента.
   *
   * @param {ConfigurationService} configurationService
   * @param {CitiesService} citiesService
   * @param {TranslateService} translateService
   */
  constructor(private readonly configurationService: ConfigurationService,
              private readonly citiesService: CitiesService,
              private readonly translateService: TranslateService) {
    this.cityCtrl.valueChanges.subscribe(firstLetters => {
      this.filteredCities = this.citiesService.findCity(firstLetters)
        .pipe(map(elem => elem.data));
    });
  }

  /**
   * Добавить дефолтный город в список. Выполняется, если не сработала геолокация
   */
  private addDefaultCity(): void {
    this.citiesService.getCities([DEFAULT_CITY_ID])
      .subscribe((citiesResponse: CitiesResponse) => {
        const defaultCity = [citiesResponse[DEFAULT_CITY_ID][0], '', '', '', citiesResponse[DEFAULT_CITY_ID][1],
          DEFAULT_CITY_ID, citiesResponse[DEFAULT_CITY_ID][2]];
        this.cities.push(defaultCity);
        // обновляем признак отсутствия городов
        this.noCities = !this.cities.length;
        this.cityAdded.emit(defaultCity);
      });
  }

  /**
   * Обработчик добавления города в список городов
   *
   * @param {MatAutocompleteSelectedEvent} event событие с данными города
   */
  onCityAddHandler(event: MatAutocompleteSelectedEvent): void {
    // проверить, нету ли уже такого города в массиве
    const isFound = this.cities.find(elem => elem[5] === event.option.value[5]);
    // если не найден
    if (!isFound) {
      // добавить
      this.cities.push(event.option.value);
      // обновляем признак отсутствия городов
      this.noCities = !this.cities.length;
      // очистить поле ввода
      this.cityInput.nativeElement.value = '';
      // эмитировать событие
      this.cityAdded.emit(event.option.value);
    }
  }

  /**
   * Обработчик удаления города из списка
   *
   * @param {CityData} city удаляемый город
   */
  onCityRemoveHandler(city: CityData): void {
    // ищем индекс удаляемого города по ID
    const elemIndex = this.cities.findIndex(elem => elem[5] === city[5]);
    // если он найден
    if (elemIndex > -1) {
      // удаляем город
      this.cities.splice(elemIndex, 1);
      // обновляем признак отсутствия городов
      this.noCities = !this.cities.length;
      // эмитируем событие
      this.cityDeleted.emit(city);
    }
  }

  // -----------------------------
  //  Lifecycle functions
  // -----------------------------

  ngOnInit() {
    // Получаем город по гелолокации
    this.citiesService.getClientCity()
      .subscribe((geoResponse: GeoLocationResponse) => {
        // если в респонсе что-то есть
        if (geoResponse && geoResponse.city) {
          // получаем текущий язык
          const currLang = this.translateService.currentLang.toLowerCase();
          // если на этом языке есть город в ответе (а его нет для укр и пол)
          if (geoResponse.city[`name_${currLang}`]) {
            // ищем по нему остальную информацию
            this.citiesService.findCity(geoResponse.city[`name_${currLang}`])
              .subscribe((searchResponse: SearchResponse) => {
                // если информация найдена
                if (searchResponse && searchResponse.data && searchResponse.data[0]) {
                  // добавляем в список
                  this.cities.push(searchResponse.data[0]);
                  // обновляем признак отсутствия городов
                  this.noCities = !this.cities.length;
                  // эмитировать событие
                  this.cityAdded.emit(searchResponse.data[0]);
                }
              });
          } else {
            // если на этом языке нет города, то показываем дефолтный
            this.addDefaultCity();
          }
        } else {
          // если в респонсе ничего нет, то показываем тоже дефолтный город
          this.addDefaultCity();
        }
      });
    // Подписка на смену языка
    this.translateService.onLangChange.subscribe(() => {
      if (!this.firstChange) {
        this.citiesService.getCities(this.cities.map(elem => elem[5]))
          .subscribe((citiesResponse: CitiesResponse) => {
            const updatedCities = [];
            this.cities.forEach((cityData: CityData) => {
              citiesResponse[cityData[5]][2] = citiesResponse[cityData[5]][2] || citiesResponse[cityData[5]][0];
              updatedCities.push(citiesResponse[cityData[5]]);
              cityData[0] = citiesResponse[cityData[5]][0];
              cityData[4] = citiesResponse[cityData[5]][1];
              cityData[6] = citiesResponse[cityData[5]][2];
            });
            this.citiesUpdated.emit(updatedCities);
          });
      }
      this.firstChange = false;
    });
  }

}
