import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { City } from "../interfaces/city";
import { CodeRequest } from "../interfaces/code-request";
import { CodeResponse } from "../interfaces/code-response";
import { ConfigurationService } from "./configuration.service";

/**
 * Сервис генерации кода информера
 */
@Injectable()
export class CodeGenService {

  /**
   * Функция для подготовки постинга параметров на back-end
   */
  private static serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }

  /**
   * Конструктор сервиса.
   *
   * @param {HttpClient} http
   * @param {ConfigurationService} configurationService
   * @param {TranslateService} translateService
   */
  constructor(
               private http: HttpClient,
               private configurationService: ConfigurationService,
               private translateService: TranslateService) {
  }

  /**
   * Функция получения идентификатора набора параметров информера из базы
   */
  public getCodeParams(params: CodeRequest, cities: City[]): Observable<CodeResponse> {
    const headers = new HttpHeaders({"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"});
    params.gencode = this.buildCodeString(cities, params.domain, params.width, JSON.stringify(params));

    return this.http.post(this.configurationService.getCodeUrl(),
                          CodeGenService.serialize(params),
                          {headers}) as Observable<CodeResponse>;
  }

  /**
   * Функция получения полного кода информера
   */
  public getCode(codeResponse: CodeResponse, cities: City[], width: number): string {
    const dataParams = {
      city_ids: codeResponse.city_ids,
      domain: codeResponse.domain,
      id: codeResponse.text,
      lang: codeResponse.lang};

    return this.buildCodeString(cities, dataParams.domain, width, JSON.stringify(dataParams));
  }

  /**
   * Функция, возвращающая код прелоадеров для информеров
   */
  private buildCodeString(cities: City[], domain: string, width: number, dataParams: string): string {
    const weatherIn = this.translateService.instant("weather_in_city_of");
    const weather2weeks = this.translateService.instant("weather_for_2_weeks");
    let citiesLoading = "";

    for (const city of cities) {
      citiesLoading += `
        <a title="${weatherIn} ${city[0]}" target="_blank" href="${domain}weather/${city[1]}">
          <img style="margin: 0 auto; display: block"
          src="https://www.meteoprog.ua/images/preloader.gif" alt="Loading...">
        </a>
        <a target="_blank" class="constructor__met2wlink" href="${domain}review/${city[1]}/">${weather2weeks}</a>
      `;
    }

    return `
    <link type="text/css" rel="stylesheet" href="https://www.meteoprog.ua/css/winformer.min.css">

    <div class="meteoprog-informer" style="width: ${width}px" data-params='${dataParams}'>

      ${citiesLoading}

      <a class="constructor__metlink" target="_blank" href="${domain}">
        <img style="display: block; margin: 0 auto;" alt="Meteoprog"
        src="https://www.meteoprog.ua/images/meteoprog-inf.png">
      </a>
    </div>
    <script type="text/javascript" src="https://www.meteoprog.ua/js/winformer.min.js"></script>
    `;

  }
}
