import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';
import { SearchResponse } from '../interfaces/search-response';
import { CitiesResponse } from '../interfaces/cities-response';
import { CitiesWeatherInfo } from '../interfaces/cities-weather-info';
import { GEO_LOCATION_URL } from '../configuration';
import { GeoLocationResponse } from '../interfaces/geo-location-response';

@Injectable()
export class CitiesService {
  constructor (private readonly http: HttpClient, private readonly configurationService: ConfigurationService) {}

  getClientCity(): Observable<GeoLocationResponse> {
    return this.http.get(GEO_LOCATION_URL) as Observable<GeoLocationResponse>;
  }

  findCity(q: string): Observable<SearchResponse>  {
    const findCityURL = `${this.configurationService.getFindCityUrl()}?q=${q}`;
    return this.http.get(findCityURL) as Observable<SearchResponse>;
  }

  getCities(cityIDs: Array<string>): Observable<CitiesResponse> {
    const getCitiesURL = this.configurationService.getCitiesUrl();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });

    const options = { headers: headers};
    const reqBody = 'city_ids[]=' + cityIDs.join('&city_ids[]=');

    return this.http.post(getCitiesURL, reqBody, options) as Observable<CitiesResponse>;
  }

  getWeather(cityIDs: Array<string>): Observable<CitiesWeatherInfo> {
    const url = this.configurationService.getWeatherUrl();
    const {reqBody, options} = this.preparePostRequest(cityIDs);

    return this.http.post(url, reqBody, options) as Observable<CitiesWeatherInfo>;
  }

  private preparePostRequest(cityIDs: Array<string>): {reqBody: string; options: {headers: HttpHeaders}} {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });

    return {
      reqBody: 'city_ids[]=' + cityIDs.join('&city_ids[]='),
      options: { headers: headers}
    };
  }
}
