import {Injectable} from '@angular/core';
import {
  AVATARS,
  BASE_URL,
  BASE_URL_PL,
  DEFAULT_SETTINGS,
  EXT_FORECAST,
  FIND_CITY_URL,
  GET_CITIES_URL,
  GET_CODE_URL,
  GET_WEATHER_URL,
  THEMES
} from '../configuration';
import {TranslateService} from '@ngx-translate/core';
import {Settings} from '../interfaces/settings';
import {OptionItem} from '../interfaces/option-item';
import {ThemeInfo} from '../interfaces/theme-info';
import {ThemeItem} from '../interfaces/theme-item';
import {InformerSize} from '../enums/informer-size.enum';
import {Language} from '../enums/language.enum';

@Injectable()
export class ConfigurationService {

  private _informerSettings: Settings;
  private _themeInfo: ThemeInfo;
  private _forecastOptions: Array<OptionItem>;
  private _themeOptionItems: Array<ThemeItem>;
  private _bgImage: string;
  private getBaseUrl = (): string => this.translateService.currentLang === Language.Pln ? BASE_URL_PL : BASE_URL;

  constructor(private readonly translateService: TranslateService) {
    this.resetSettings();
  }
  getCitiesUrl = (): string => `${this.getBaseUrl()}${this.translateService.currentLang}/${GET_CITIES_URL}`;
  getFindCityUrl = (): string => `${this.getBaseUrl()}${this.translateService.currentLang}/${FIND_CITY_URL}`;
  getCodeUrl = (): string => `${this.getBaseUrl()}${this.translateService.currentLang}/${GET_CODE_URL}`;
  getWeatherUrl = (): string => `${this.getBaseUrl()}${this.translateService.currentLang}/${GET_WEATHER_URL}`;

  get informerSettings(): Settings {
    return this._informerSettings;
  }

  get opacity(): number {
    return 100 * (1 - this._informerSettings.opacity);
  }

  set opacity(value: number) {
    this._informerSettings.opacity = 1 - value / 100;
    this._themeInfo.settings.headerColor.a = this._informerSettings.opacity;
    this._themeInfo.settings.bodyColor.a = this._informerSettings.opacity;
  }

  get avatarOptions(): Array<OptionItem> {
    return [...AVATARS];
  }

  get themeInfo(): ThemeInfo {
    return this._themeInfo;
  }

  set themeInfo(value: ThemeInfo) {
    this._informerSettings.theme = value.theme;
    this._themeInfo = value;
    // Прозрачность берем из общих настроек
    this._themeInfo.settings.headerColor.a = this._informerSettings.opacity;
    this._themeInfo.settings.bodyColor.a = this._informerSettings.opacity;
  }

  /**
   * Опции отображения прогноза погоды
   */
  get forecastOptions(): Array<OptionItem> {
    return this._forecastOptions;
  }

  /**
   * Опции для настройки тем
   */
  get themeOptionItems(): Array<ThemeItem> {
    return this._themeOptionItems;
  }

  get informerSize(): InformerSize {
    return this._informerSettings.width >= 260 ? InformerSize.XLarge :
      this._informerSettings.width > 200 ? InformerSize.Large :
    this._informerSettings.width >= 150 ? InformerSize.Medium : InformerSize.Small;
  }

  get bgImage(): string {
    return this._bgImage;
  }

  set bgImage(value: string) {
    this._bgImage = value;
  }

  resetSettings(): void {
    this._bgImage = 'none';
    this._forecastOptions = [...EXT_FORECAST];
    this._informerSettings = {...DEFAULT_SETTINGS};
    // другие способы копировния не работали :-(
    this._themeOptionItems = JSON.parse(JSON.stringify(THEMES));
    this._themeInfo = {
      theme: this._informerSettings.theme,
      settings: {...THEMES[this._informerSettings.theme].settings}
    };
  }

}
