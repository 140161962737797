import { Component, Input, OnInit } from '@angular/core';
import { Informer } from '../../interfaces/informer';
import { ConfigurationService } from '../../services/configuration.service';
import {Avatar} from '../../enums/avatar.enum';
import {Forecast} from '../../enums/forecast.enum';

@Component({
  selector: 'app-informer',
  templateUrl: './informer.component.html',
  styleUrls: ['./informer.component.scss']
})
export class InformerComponent implements OnInit {

  readonly Avatar = Avatar;
  readonly Forecast = Forecast;
  readonly weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  weekDay: string;
  dayTime: string;

  readonly informerSizes = ['informer_size_small', 'informer_size_medium', 'informer_size_large', 'informer_size_x-large'];

  @Input() informer: Informer;

  constructor(readonly configurationService: ConfigurationService) { }

  ngOnInit() {
    const dateObj = new Date();
    this.weekDay = this.weekDays[dateObj.getDay()];
    const hour = dateObj.getHours();
    this.dayTime = hour < 6 ? 'night' : hour < 11 ? 'morning' : hour < 18 ? 'day' : 'evening';
  }

}
