import { Pipe, PipeTransform } from '@angular/core';
import {DEFAULT_WIDTH_MINIMAL} from '../configuration';

@Pipe({
  name: 'realwidth',
  pure: false
})
export class RealwidthPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    return value <= DEFAULT_WIDTH_MINIMAL ? 'auto' : `${value}px`;
  }

}
