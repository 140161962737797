import { Pipe, PipeTransform } from '@angular/core';
import {Avatar} from '../enums/avatar.enum';
import {CityWeatherInfo} from '../interfaces/city-weather-info';

@Pipe({
  name: 'avaicon',
  pure: false
})
export class AvaiconPipe implements PipeTransform {

  transform(value: Avatar, ...args: any[]): any {
      const avPrefix = 'ava_';
      const avMain = value === Avatar.Man ? 'boy_' : value === Avatar.Woman ? 'girl_' : '';
      const weather = args[0] as CityWeatherInfo;
      const avSuffix = weather.temperature >= 30 ? '30' :
                       weather.temperature >= 25 ? '25' :
                       weather.temperature >= 20 ? '20' :
                       weather.temperature >= 15 ? '15' :
                       weather.temperature >= 10 ? '10' :
                       weather.temperature >= 0 ? '0' :
                       weather.temperature >= -5 ? '-5' :
                       weather.temperature >= -10 ? '-10' :
                       weather.temperature >= -15 ? '-15' :
                       weather.temperature >= -20 ? '-20' :
                       '-99';

      const isRain = weather.imageName.indexOf('rain') > -1;

      return `assets/images/avatar/${avPrefix}${avMain}${avSuffix}${isRain ? '_rain' : ''}.png`;
  }

}
