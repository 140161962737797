import { Component } from '@angular/core';
import {InformerComponent} from '../informer/informer.component';

@Component({
  selector: 'app-informer-light',
  templateUrl: '../informer/informer.component.html',
  styleUrls: ['../informer/informer.component.scss']
})
export class InformerLightComponent extends InformerComponent {

}
