import { Component, OnInit, Input } from '@angular/core';
import {Informer} from '../../interfaces/informer';
import {ConfigurationService} from '../../services/configuration.service';
import {Theme} from '../../enums/theme.enum';

@Component({
  selector: 'app-informers-area',
  templateUrl: './informers-area.component.html',
  styleUrls: ['./informers-area.component.scss']
})
export class InformersAreaComponent implements OnInit {

  readonly Theme = Theme;

  @Input() informers: Array<Informer>;


  constructor(readonly configurationService: ConfigurationService) { }


  ngOnInit(): void {
  }

}
