import {Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {ColorEvent, RGBA} from 'ngx-color';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ColorPickerComponent),
  multi: true
};


@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class ColorPickerComponent implements OnInit {

  private _color: RGBA;
  @Input() label: string;

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  private compareColors(v: RGBA): boolean {
    return this._color && (this._color.r === v.r) && (this._color.g === v.g) && (this._color.b === v.b) && (this._color.a === v.a);
  }

  // get accessor
  get value(): RGBA {
    return this._color;
  };

  // set accessor including call the onchange callback
  set value(v: RGBA) {
    if (!this.compareColors(v)) {
      this._color = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: RGBA) {
    if (value) {
      if (!this.compareColors(value)) {
        this._color = value;
      }
    } else {
      this._color = null;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnInit() {
  }

  changeColorComplete(event: ColorEvent): void {
    if (event && event.color) {
      this._color = event.color.rgb;
      this.onChangeCallback(this._color);
    }
  }

}
