import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InformersAreaComponent } from './components/informers-area/informers-area.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

import { CitiesService } from './services/cities.service';
import { CodeGenService } from './services/codegen.service';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { RangeComponent } from './components/range/range.component';
import { ThemeSettingsComponent } from './components/theme-settings/theme-settings.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { InformerComponent } from './components/informer/informer.component';
import { ConfigurationService } from './services/configuration.service';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';
import { CitySelectComponent } from './components/city-select/city-select.component';
import { InformerCheckboxComponent } from './components/informer-checkbox/informer-checkbox.component';
import { HideOnOutsideClickDirective } from './directives/hideonoutsideclick.directive';
import { InformerStandartComponent } from './components/informer-standart/informer-standart.component';
import { InformerLightComponent } from './components/informer-light/informer-light.component';
import { InformerDarkComponent } from './components/informer-dark/informer-dark.component';
import { InformerMinimalComponent } from './components/informer-minimal/informer-minimal.component';
import { RgbaPipe } from './pipes/rgba.pipe';
import { AvaiconPipe } from './pipes/avaicon.pipe';
import { RealwidthPipe } from './pipes/realwidth.pipe';
import { BgurlPipe } from './pipes/bgurl.pipe';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    InformersAreaComponent,
    LangSwitcherComponent,
    RangeComponent,
    ThemeSettingsComponent,
    RadioGroupComponent,
    InformerComponent,
    ColorPickerComponent,
    ThemeSwitcherComponent,
    CitySelectComponent,
    InformerCheckboxComponent,
    HideOnOutsideClickDirective,
    InformerStandartComponent,
    InformerLightComponent,
    InformerDarkComponent,
    InformerMinimalComponent,
    RgbaPipe,
    AvaiconPipe,
    RealwidthPipe,
    BgurlPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    ColorSketchModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    RecaptchaModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
  ],
  providers: [
    ConfigurationService,
    CitiesService,
    CodeGenService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LdyhR4UAAAAALLsOgz4mhknNiD74E_k_PIO3s25' } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'ru', // use Russian language
    },
    RgbaPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
