import { Component } from '@angular/core';
import {InformerComponent} from '../informer/informer.component';

@Component({
  selector: 'app-informer-minimal',
  templateUrl: './informer-minimal.component.html',
  styleUrls: ['./informer-minimal.component.scss']
})
export class InformerMinimalComponent extends InformerComponent {
  readonly iconSizes = ['23x22', '35x36', '48x50', '72x75'];
}
