import {Injectable, Pipe, PipeTransform} from '@angular/core';
import { RGBA } from 'ngx-color';

@Pipe({
  name: 'rgba',
  pure: false
})
export class RgbaPipe implements PipeTransform {

  transform(value: RGBA, ...args: any[]): string {
    return `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
  }

}
