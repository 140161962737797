import {Component, forwardRef, Input } from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {MAX_RANDOM_ID} from '../../configuration';
const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InformerCheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-informer-checkbox',
  templateUrl: './informer-checkbox.component.html',
  styleUrls: ['./informer-checkbox.component.scss'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class InformerCheckboxComponent {

  private _isChecked: boolean;
  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input() label: string;

  randomID = Math.floor((Math.random() * MAX_RANDOM_ID) + 1);

  constructor() { }


  // get accessor
  get value(): boolean {
    return this._isChecked;
  };

  // set accessor including call the onchange callback
  set value(v: boolean) {
    if (this._isChecked !== v) {
      this._isChecked = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  writeValue(v: boolean) {
    if (this._isChecked !== v) {
      this._isChecked = v;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}
