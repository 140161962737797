import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHideOnOutsideClick]'
})
export class HideOnOutsideClickDirective {

  @Input() appHideOnOutsideClick: HTMLElement;

  constructor(private el: ElementRef, private renderer2: Renderer2) { }

  @HostListener('document:click', ['$event']) onClick(event) {
    if (this.appHideOnOutsideClick) {
      const inElement = this.el.nativeElement.contains(event.target);
      this.renderer2.setStyle(this.appHideOnOutsideClick, 'display', inElement ? 'block' : 'none');
    }
  }
}
