import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bgurl',
  pure: false
})
export class BgurlPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    return value === 'none' ? 'none' : `url('${value}')`;
  }

}
