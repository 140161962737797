import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { OptionItem } from '../../interfaces/option-item';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {MAX_RANDOM_ID} from '../../configuration';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioGroupComponent),
  multi: true
};


@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class RadioGroupComponent implements ControlValueAccessor, OnInit {

  private _value = 0;

  // Placeholders for the callbacks which are later provides
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  isImaged = false;

  @Input() options: Array<OptionItem>;
  @Input() label: string;
  @Input() name: string;

  /**
   * Случайный ID для связи ползунка и подписи к нему
   */
  randomID = Math.floor((Math.random() * MAX_RANDOM_ID) + 1);

  // get accessor
  get value(): number {
    return this._value;
  };

  // set accessor including call the onchange callback
  set value(v: number) {
    if (this._value !== v) {
      this._value = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  writeValue(v: number) {
    if (Number.isInteger(v) && this._value !== v) {
      this._value = v;
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnInit() {
    this.isImaged = !!this.options.find(elem => !!elem.image);
  }

}
