import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {

  @Input() label = 'informer_language';
  @Input() languages: Array<string>;

  constructor(readonly translateService: TranslateService) { }

  changeLanguage(event): void {
    this.translateService.use((event.target as HTMLInputElement).value);
  }

  ngOnInit() {
    this.translateService.addLangs(this.languages);
  }

}
